<template>
  <div class="modal">
    <div class="modal__container">
      <div class="club-modal">
        <button
          class="club-modal__close"
          @click="close"
        ></button>
        <div class="club-modal__title">
          {{ $t("modalHidden.title") }}
        </div>
        <div class="club-modal__descr">
          {{ $t("modalHidden.text") }}
        </div>
        <button
          class="club-modal__button"
          @click="openAuthModal"
        >
          {{ $t("modalHidden.btn") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import { actionTypes } from "@/store"

export default {
  name: "AppModalHidden",
  methods: {
    ...mapActions({
      openIsAuthActive: actionTypes.changeIsAuthActive,
    }),
    close() {
      this.$emit("close")
    },
    openAuthModal() {
      this.openIsAuthActive({ page: "heating" })
      this.$emit("close")
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
.modal {
  z-index: 999;
}
.club-modal {
  max-width: 370px;
  text-align: left;
}
.club-modal__title {
  font-size: 24px;
  line-height: 110%;
  color: #2d2f32;
}

.club-modal__button {
  background: linear-gradient(270.64deg, #8344d6 3.8%, #9c52fc 96.87%);
  box-shadow: 0px 4px 20px rgba(55, 0, 128, 0.2);
  border-radius: 10px;
  color: #fff;
}
</style>
